import React from "react";
import Header from "../components/Header/Header";
const CommonQuestion = () => {
  return (
    <div>
      <Header />
      CommonQuestion
    </div>
  );
};

export default CommonQuestion;
